import { string } from 'prop-types';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import BaseCard from '@bit/medicalwebexperts.mwe-ui.card';
import Divider from './Divider';
import Footer from './Footer';
import Title from './Title';
import Body from './Body';

const propTypes = {
  boxShadow: string,
};

const defaultProps = {
  boxShadow: 'none',
};

const Card = styled(BaseCard)(
  css({
    borderRadius: 'lg',
    overflow: 'visible',
    border: 'none',
    borderTop: '6px solid',
    borderTopColor: 'primary.dark',
  }),
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

Card.Divider = Divider;
Card.Footer = Footer;
Card.Title = Title;
Card.Body = Body;

export default Card;
