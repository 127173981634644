import axios from 'axios';

const createClient = (configs) => {
  const {
    prefixToken = 'Bearer',
    tokenName = 'access',
    refreshTokenName = 'refresh',
    requestInterceptors = () => [],
    responseInterceptors = () => [],
    ...axiosConfigs
  } = configs;
  const http = axios.create(axiosConfigs);

  const setToken = (token) => {
    http.defaults.headers.common.Authorization = `${prefixToken} ${token}`;
  };

  http.$getToken = () => localStorage.getItem(tokenName);
  http.$getRefreshToken = () => localStorage.getItem(refreshTokenName);

  http.$setToken = (token, refresh) => {
    setToken(token);
    localStorage.setItem(tokenName, token);
    if (refresh) localStorage.setItem(refreshTokenName, refresh);
  };

  http.$clearToken = () => {
    delete http.defaults.headers.common.Authorization;
    localStorage.removeItem(tokenName);
    localStorage.removeItem(refreshTokenName);
  };

  http.$refreshToken = (e) => {
    const originalRequest = e.config;
    const refresh = http.$getRefreshToken();

    if (originalRequest.retryRefresh || !refresh) {
      http.$clearToken();
      return Promise.reject(e);
    }

    originalRequest.retryRefresh = true;

    return axios
      .post(`${axiosConfigs.baseURL}/auth/refresh/`, {
        refresh,
      })
      .then(({ status, data }) => {
        if (status === 200 && data?.access) {
          originalRequest.headers.Authorization = `${prefixToken} ${data.access}`;
          http.$setToken(data.access);
          return http(originalRequest);
        }

        return Promise.reject(e);
      });
  };

  requestInterceptors(http).forEach((interceptor) =>
    http.interceptors.request.use(...interceptor),
  );
  responseInterceptors(http).forEach((interceptor) =>
    http.interceptors.response.use(...interceptor),
  );

  if (http.$getToken()) {
    setToken(http.$getToken());
  }

  return http;
};

const client = createClient({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  responseInterceptors(http) {
    return [
      [
        (response) => {
          return response.data;
        },
        (error) => {
          if (
            error?.response?.status === 401 &&
            error?.response?.data?.code === 'token_not_valid'
          ) {
            return http.$refreshToken(error);
          }
          return Promise.reject(error);
        },
      ],
    ];
  },
});

export default client;
