import React from 'react';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Avatar from '@bit/medicalwebexperts.mwe-ui.avatar';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import { useAuth } from '@bit/medicalwebexperts.mwe-ui.auth-context';

const LoggedUserAvatar = () => {
  const { user } = useAuth();
  const userName = `${user.lastName} ${user.firstName}`;

  return (
    <Flex alignItems="center">
      <Avatar
        size="xs"
        name={userName}
        classes={{ root: { size: 8, fontSize: 'sm', mr: 2 } }}
      />
      <Typography variant="body2">{userName}</Typography>
    </Flex>
  );
};

export default LoggedUserAvatar;
