import React, { Fragment } from 'react';
import { node, elementType } from 'prop-types';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import { useUp } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import MobileHeader from './Headers/MobileHeader';

const propTypes = {
  children: node.isRequired,
  desktopHeader: elementType,
};

const defaultProps = {
  desktopHeader: Fragment,
};

const LayoutBase = ({ children, desktopHeader: DesktopHeader }) => {
  const isDesktop = useUp('lg');
  const Header = isDesktop ? DesktopHeader : MobileHeader;

  return (
    <Flex as="main" minHeight="100vh" flexDirection="column">
      <Header />
      {children}
    </Flex>
  );
};

LayoutBase.propTypes = propTypes;
LayoutBase.defaultProps = defaultProps;

export default LayoutBase;
