import preset from '@bit/medicalwebexperts.mwe-ui.themes.preset';

import typography from './typography';
import icons from './icons';

export default {
  ...preset,
  fonts: {
    ...preset.fonts,
    body: 'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
  },
  colors: {
    ...preset.colors,
    text: '#14213F',
    success: '#00B915',
    danger: '#DF1A1A',
    background: '#F6F8FC',
    primary: {
      main: '#1E9AD1',
      dark: '#1999CF',
    },
    secondary: {
      main: '#442246',
    },
  },
  sizes: {
    ...preset.sizes,
  },
  typography,
  icons,
};
