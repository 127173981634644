import React from 'react';

const MedicalHistoryTwo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
  >
    <defs>
      <clipPath id="clip-Medical-history-icon_2">
        <rect width="27" height="27" />
      </clipPath>
    </defs>
    <g
      id="Medical-history-icon_2"
      data-name="Medical-history-icon – 2"
      clipPath="url(#clip-Medical-history-icon_2)"
    >
      <g id="checked" transform="translate(1 1.417)">
        <g
          id="Group_220"
          data-name="Group 220"
          transform="translate(7.523 2.765)"
        >
          <g id="Group_219" data-name="Group 219">
            <path
              id="Path_62"
              data-name="Path 62"
              d="M137.723,64.571a.829.829,0,0,0-1.173,0L126.6,74.5l-3.591-3.9a.83.83,0,0,0-1.221,1.124l4.175,4.535a.829.829,0,0,0,.593.267h.018a.83.83,0,0,0,.586-.242l10.563-10.535A.829.829,0,0,0,137.723,64.571Z"
              transform="translate(-121.568 -64.327)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g id="Group_222" data-name="Group 222">
          <g id="Group_221" data-name="Group 221">
            <path
              id="Path_63"
              data-name="Path 63"
              d="M23.391,11.238a.916.916,0,0,0-.916.916A10.321,10.321,0,1,1,12.154,1.832a.916.916,0,1,0,0-1.832A12.154,12.154,0,1,0,24.307,12.154.916.916,0,0,0,23.391,11.238Z"
              fill="#1999cf"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MedicalHistoryTwo;
