import { LANGUAGES } from '../constants/languages';

function getCurrentLanguage(i18n) {
  let currentLanguage = i18n.language.split('-')[0];
  if (currentLanguage === 'zh') {
    currentLanguage = LANGUAGES.ZH;
  }
  return currentLanguage;
}

export default getCurrentLanguage;
