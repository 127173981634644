import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES_LABELS, LANGUAGES_LIST } from '../../constants/languages';
import Dropdown from '../Dropdown/Dropdown';
import getCurrentLanguage from '../../utils/getCurrentLanguage';

const dropdownStyles = {
  root: {
    bg: 'white',
    fontSize: 'sm',
    border: '1px solid',
    borderColor: 'gray.200',
    textTransform: 'initial',
    span: {
      ml: 3,
      color: 'text',
    },
  },
};

const LangDropdown = () => {
  const { i18n } = useTranslation();

  const handleClick = (hide, lng) => () => {
    i18n.changeLanguage(lng);
    hide();
  };

  return (
    <Dropdown
      size="sm"
      variant="text"
      variantColor="default"
      ariaLabel="Language dropdown"
      toggle={LANGUAGES_LABELS[getCurrentLanguage(i18n)]}
      classes={dropdownStyles}
    >
      {({ hide }) =>
        Children.toArray(
          LANGUAGES_LIST.map(({ label, value }) => (
            <Dropdown.Item onClick={handleClick(hide, value)}>
              {label}
            </Dropdown.Item>
          )),
        )
      }
    </Dropdown>
  );
};

export default LangDropdown;
