import axios from 'axios';
import client from './client';
import usersClient from './usersClient';

const authClient = {
  async login(values) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BPP_API_BASE_URL}/en/identity/user`,
      values,
    );

    return data;
  },
  async verification(values) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BPP_API_BASE_URL}/en/identity/option`,
      values,
    );

    return data;
  },
  async authenticate(values) {
    const { access, refresh } = await client.post('/auth/code/', values);
    client.$setToken(access, refresh);

    return usersClient.me();
  },
  async logout() {
    client.$clearToken();
  },
  reAuthenticate() {
    if (!client.$getToken() && !client.$getRefreshToken()) {
      throw new Error('Missing auth tokens');
    }

    return usersClient.me();
  },
};

export default authClient;
