import React from 'react';
import { bool } from 'prop-types';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Card from '@bit/medicalwebexperts.mwe-ui.card';

const propTypes = {
  withBorder: bool,
  dense: bool,
};

const defaultProps = {
  withBorder: false,
  dense: false,
};

const CardFooter = styled(Card.Footer)(({ withBorder, dense }) =>
  css({
    px: [8, 10],
    pb: dense ? 3 : [8, 10],
    pt: dense ? 3 : 0,
    ...(withBorder
      ? {
          borderTop: '1px solid',
          borderTopColor: 'gray.200',
        }
      : null),
  }),
);

const Footer = ({ ...props }) => <CardFooter {...props} />;

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
