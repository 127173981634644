import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { string, bool } from 'prop-types';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import BaseTypography from '@bit/medicalwebexperts.mwe-ui.typography';

const propTypes = {
  title: string.isRequired,
  notUnderlined: bool,
  infoTitle: bool,
};

const defaultProps = {
  notUnderlined: false,
  infoTitle: false,
};

const Wrapper = styled(Flex)(
  ({ infoTitle }) =>
    css({
      pb: 3,
      pt: infoTitle ? 4 : [8, 10],
      alignItems: 'center',
      width: '100%',
    }),
  ({ border, infoTitle }) =>
    border &&
    css({
      borderBottom: '1px solid',
      borderBottomColor: 'gray.200',
      mb: infoTitle ? 8 : null,
    }),
);

const Typography = styled(BaseTypography)(
  css({
    textTransform: 'uppercase',
    fontWeight: 'medium',
  }),
);

const Title = ({ title, notUnderlined, infoTitle }) => (
  <Card.Header py={0} px={infoTitle ? 1 : [8, 10]}>
    <Wrapper border={!notUnderlined} infoTitle={infoTitle}>
      <Typography>{title}</Typography>
    </Wrapper>
  </Card.Header>
);

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
