import React from 'react';

const Privacy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="22"
    height="27"
    viewBox="0 0 22 27"
  >
    <defs>
      <clipPath id="clip-Privacy-icon">
        <rect width="22" height="27" />
      </clipPath>
    </defs>
    <g id="Privacy-icon" clipPath="url(#clip-Privacy-icon)">
      <g id="contract" transform="translate(1.861 1.403)">
        <path
          id="Path_98"
          data-name="Path 98"
          d="M19.1,1.3,3.978.324a.887.887,0,0,0-.954.805L2.906,2.838H.9A.882.882,0,0,0,0,3.7v21.03a.882.882,0,0,0,.9.865H12.474a.4.4,0,0,0,.271-.108l1.748-1.68a.394.394,0,0,0,.055.008l2.984.193a.908.908,0,0,0,.954-.805L19.939,2.222A.883.883,0,0,0,19.1,1.3ZM12.858,24.329V21.754a.126.126,0,0,1,.128-.124h2.657Zm4.861-1.174a.127.127,0,0,1-.136.115l-2.369-.153,1.626-1.562a.364.364,0,0,0,.113-.263V3.7a.882.882,0,0,0-.9-.865H12.063a.371.371,0,1,0,0,.741h3.993a.126.126,0,0,1,.128.124V20.889h-3.2a.882.882,0,0,0-.9.865v3.1H.9a.126.126,0,0,1-.128-.124V3.7A.126.126,0,0,1,.9,3.579h9.122a.371.371,0,1,0,0-.741H3.676l.115-1.659a.126.126,0,0,1,.136-.115l15.126.977a.125.125,0,0,1,.119.132Z"
          transform="translate(0 0)"
          fill="#1e9ad1"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M65.062,237.047H56.1a.328.328,0,1,0,0,.657h8.959a.328.328,0,1,0,0-.657Z"
          transform="translate(-53.331 -224.984)"
          fill="#1e9ad1"
        />
        <path
          id="Path_100"
          data-name="Path 100"
          d="M65.062,289.332H56.1a.329.329,0,0,0,0,.657h8.959a.329.329,0,0,0,0-.657Z"
          transform="translate(-53.331 -274.676)"
          fill="#1e9ad1"
        />
        <path
          id="Path_101"
          data-name="Path 101"
          d="M55.774,185.09a.329.329,0,0,0,.328.329h8.959a.329.329,0,0,0,0-.657H56.1A.329.329,0,0,0,55.774,185.09Z"
          transform="translate(-53.331 -175.292)"
          fill="#1e9ad1"
        />
        <path
          id="Path_102"
          data-name="Path 102"
          d="M91.807,132.806a.329.329,0,0,0,.328.328h5.8a.328.328,0,1,0,0-.657h-5.8A.329.329,0,0,0,91.807,132.806Z"
          transform="translate(-87.06 -125.6)"
          fill="#1e9ad1"
        />
        <path
          id="Path_103"
          data-name="Path 103"
          d="M59.554,389.145l-1.772,0h-.6c-.36,0-.6-.072-.683-.468-.068-.335-.542-.29-.634,0-.227.716-.6.435-.814-.046a6.739,6.739,0,0,1-.356-1.529c-.067-.375-.635-.27-.645.087a6.121,6.121,0,0,1-.177,1.391,11.162,11.162,0,0,1-.492,1.374c-.16.392.476.561.634.175a12.683,12.683,0,0,0,.446-1.214c.225.541.691,1.154,1.355.793a1.322,1.322,0,0,0,.34-.274,1.606,1.606,0,0,0,1.34.367l2.053,0A.329.329,0,0,0,59.554,389.145Z"
          transform="translate(-51.024 -366.74)"
          fill="#1e9ad1"
        />
      </g>
    </g>
  </svg>
);

export default Privacy;
