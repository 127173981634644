import React from 'react';

const MedicalHistoryThree = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="27"
    viewBox="0 0 24 27"
  >
    <defs>
      <clipPath id="clip-Medical-history-icon">
        <rect width="24" height="27" />
      </clipPath>
    </defs>
    <g id="Medical-history-icon" clipPath="url(#clip-Medical-history-icon)">
      <g id="medical-history" transform="translate(-66)">
        <g
          id="Group_204"
          data-name="Group 204"
          transform="translate(72.108 17.035)"
        >
          <g id="Group_203" data-name="Group 203">
            <path
              id="Path_55"
              data-name="Path 55"
              d="M166.523,326a.523.523,0,1,0,.523.523A.523.523,0,0,0,166.523,326Z"
              transform="translate(-166 -326)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g id="Group_206" data-name="Group 206" transform="translate(66)">
          <g id="Group_205" data-name="Group 205">
            <path
              id="Path_56"
              data-name="Path 56"
              d="M87.215,2.09H80.487a3.155,3.155,0,0,0-5.94,0H67.818A1.709,1.709,0,0,0,66,3.658V25.186a1.709,1.709,0,0,0,1.818,1.568h19.4a1.709,1.709,0,0,0,1.818-1.568V3.658A1.709,1.709,0,0,0,87.215,2.09ZM73.274,3.135h1.818a.569.569,0,0,0,.606-.523,1.838,1.838,0,0,1,3.637,0,.569.569,0,0,0,.606.523H81.76V4.18H73.274ZM85.4,5.225V23.619H69.637V5.225Zm2.425,19.961a.57.57,0,0,1-.606.523h-19.4a.57.57,0,0,1-.606-.523V3.658a.57.57,0,0,1,.606-.523h4.243V4.18H69.031a.569.569,0,0,0-.606.523V24.141a.569.569,0,0,0,.606.523H86a.569.569,0,0,0,.606-.523V4.7A.569.569,0,0,0,86,4.18H82.972V3.135h4.243a.57.57,0,0,1,.606.523Z"
              transform="translate(-66)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_208"
          data-name="Group 208"
          transform="translate(72.814 20.17)"
        >
          <g id="Group_207" data-name="Group 207">
            <path
              id="Path_57"
              data-name="Path 57"
              d="M174.883,386h-8.361a.523.523,0,1,0,0,1.045h8.361a.523.523,0,1,0,0-1.045Z"
              transform="translate(-166 -386)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_210"
          data-name="Group 210"
          transform="translate(75.169 17.035)"
        >
          <g id="Group_209" data-name="Group 209">
            <path
              id="Path_58"
              data-name="Path 58"
              d="M212.793,326h-6.27a.523.523,0,1,0,0,1.045h6.27a.523.523,0,1,0,0-1.045Z"
              transform="translate(-206 -326)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_212"
          data-name="Group 212"
          transform="translate(73.859 7.629)"
        >
          <g id="Group_211" data-name="Group 211">
            <path
              id="Path_59"
              data-name="Path 59"
              d="M189.658,146a3.658,3.658,0,1,0,3.658,3.658A3.662,3.662,0,0,0,189.658,146Zm0,6.27a2.613,2.613,0,1,1,2.613-2.613A2.616,2.616,0,0,1,189.658,152.27Z"
              transform="translate(-186 -146)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_214"
          data-name="Group 214"
          transform="translate(75.949 9.719)"
        >
          <g id="Group_213" data-name="Group 213">
            <path
              id="Path_60"
              data-name="Path 60"
              d="M228.613,187.045h-.523v-.523a.523.523,0,1,0-1.045,0v.523h-.523a.523.523,0,1,0,0,1.045h.523v.523a.523.523,0,1,0,1.045,0v-.523h.523a.523.523,0,1,0,0-1.045Z"
              transform="translate(-226 -186)"
              fill="#1999cf"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MedicalHistoryThree;
