import React from 'react';
import { node } from 'prop-types';
import { useModalState } from '@bit/medicalwebexperts.mwe-ui.modal';
import BaseAuthenticationProvider from '@bit/medicalwebexperts.mwe-ui.auth-context';
import authClient from '../../services/authClient';
import LogoutTimerModal from './LogoutTimerModal';

const propTypes = {
  children: node.isRequired,
};

const AuthenticationProvider = ({ children }) => {
  const modal = useModalState({ animated: true });

  const handleOnIdle = () => modal.show();

  return (
    <BaseAuthenticationProvider
      client={authClient}
      onIdle={handleOnIdle}
      storageKey="access"
    >
      {children}
      <LogoutTimerModal state={modal} />
    </BaseAuthenticationProvider>
  );
};

AuthenticationProvider.propTypes = propTypes;

export default AuthenticationProvider;
