import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Card from '../Card/Card';
import Logo from '../Logo/Logo';
import LayoutBase from './LayoutBase';

const propTypes = {
  children: node.isRequired,
};

const Content = styled.section(
  css({
    p: 4,
    flex: '1 1 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: [16, null, null, 0],
  }),
);

const StyledCard = styled(Card)(
  css({
    width: '100%',
    minWidth: '300px',
    maxWidth: '445px',
    boxShadow: '0px 3px 20px #00000012',
  }),
);

const LoggedOutLayout = ({ children }) => (
  <LayoutBase>
    <Content>
      <StyledCard>
        <Card.Body p={[8, 10]}>
          <Logo
            mb={4}
            m="auto"
            mt="-6.5rem"
            display={['none', null, null, 'block']}
          />
          {children}
        </Card.Body>
      </StyledCard>
    </Content>
  </LayoutBase>
);

LoggedOutLayout.propTypes = propTypes;

export default LoggedOutLayout;
