import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import AppBar from '@bit/medicalwebexperts.mwe-ui.app-bar';
import LangDropdown from '../../LangDropdown/LangDropdown';
import Logo from '../../Logo/Logo';

const Toolbar = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 16,
    px: 4,
  }),
);

const MobileHeader = () => (
  <AppBar variant="default">
    <Toolbar>
      <Logo width="50px" />
      <LangDropdown />
    </Toolbar>
  </AppBar>
);

export default MobileHeader;
