import React, { useEffect } from 'react';
import Modal from '@bit/medicalwebexperts.mwe-ui.modal';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import { useAuthDispatch } from '@bit/medicalwebexperts.mwe-ui.auth-context';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';

const propTypes = {
  state: shape({}).isRequired,
};

const LogoutTimerModal = ({ state }) => {
  const { t } = useTranslation();
  const { logout } = useAuthDispatch();

  const { hide, visible } = state;

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(async () => {
        await logout();
        hide();
      }, 15000); // 15 seconds

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [visible, hide, logout]);

  return (
    <Modal
      top="10%"
      overflowY="auto"
      maxHeight="80vh"
      borderRadius="lg"
      ariaLabel="Close session"
      withBackdrop
      state={state}
      p={0}
    >
      <Modal.Body p={8} textAlign="center" width={['xs', 'md']}>
        <Typography>{t('inactiveMessage')}</Typography>
        <Button
          size="md"
          variantColor="primary.main"
          onClick={state.hide}
          classes={{
            root: {
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: 'sm',
              mt: 8,
            },
          }}
        >
          {t('common.continue')}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

LogoutTimerModal.propTypes = propTypes;

export default LogoutTimerModal;
