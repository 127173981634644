import {
  MdDone,
  MdClose,
  MdError,
  MdPrint,
  MdWarning,
  MdDateRange,
  MdAddCircle,
  MdArrowBack,
  MdCheckCircle,
  MdErrorOutline,
  MdArrowForward,
  MdCloudDownload,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
} from 'react-icons/md';
import Privacy from '../components/Icons/Privacy';
import Address from '../components/Icons/Address';
import Welcome from '../components/Icons/Welcome';
import MedicalHistoryOne from '../components/Icons/MedicalHistoryOne';
import MedicalHistoryTwo from '../components/Icons/MedicalHistoryTwo';
import MedicalHistoryThree from '../components/Icons/MedicalHistoryThree';

export default {
  MdKeyboardArrowDown,
  MdCloudDownload,
  MdErrorOutline,
  MdArrowForward,
  MdCheckCircle,
  MdDateRange,
  MdAddCircle,
  MdArrowBack,
  MdWarning,
  MdError,
  MdClose,
  MdDone,
  Privacy,
  Address,
  Welcome,
  MdPrint,
  MedicalHistoryOne,
  MedicalHistoryTwo,
  MedicalHistoryThree,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
};
