import React, { Component } from 'react';
import { func, node } from 'prop-types';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Link from '@bit/medicalwebexperts.mwe-ui.link';
import { Trans, withTranslation } from 'react-i18next';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import LoggedInLayout from '../Layout/LoggedInLayout';

import image from '../../assets/images/500.svg';

const propTypes = {
  t: func.isRequired,
  children: node.isRequired,
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children, t } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <LoggedInLayout>
          <Flex flexDirection="column" alignItems="center">
            <Flex.Box
              as="img"
              alt="500"
              width={['auto', null, '450px']}
              src={image}
              mb={8}
            />
            <Typography paragraph variant="h1" textAlign="center" mt={8}>
              {t('pages.500.title')}
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
              <Trans
                i18nKey="pages.500.paragraph"
                components={{
                  tel: <Link href="tel:(877) 775-3377" />,
                  email: (
                    <Link
                      href="mailto:customerservice@sleepdr.com"
                      color="primary.main"
                    />
                  ),
                }}
              />
            </Typography>
            <Button
              to="/"
              size="lg"
              variantColor="primary.main"
              startIcon={<Icon name="MdArrowBack" size={4} />}
              onClick={() => window.location.reload()}
              classes={{
                root: {
                  textTransform: 'none',
                  fontWeight: 'bold',
                  fontSize: 'sm',
                  mt: 16,
                },
              }}
            >
              {t('pages.500.button')}
            </Button>
          </Flex>
        </LoggedInLayout>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = propTypes;

export default withTranslation()(ErrorBoundary);
