import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import LangDropdown from '../../LangDropdown/LangDropdown';
import Logo from '../../Logo/Logo';
import LoggedUserAvatar from './LoggedUserAvatar';

const Wrapper = styled(Container)(
  css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '0 0 auto',
  }),
);

const DesktopHeader = () => (
  <Wrapper as="header">
    <Logo />
    <Flex flexDirection="column" alignItems="flex-end">
      <LoggedUserAvatar />
      <LangDropdown />
    </Flex>
  </Wrapper>
);

export default DesktopHeader;
