import React from 'react';

const MedicalHistoryOne = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="31"
    height="27"
    viewBox="0 0 31 27"
  >
    <defs>
      <clipPath id="clip-Medical-history-icon_1">
        <rect width="31" height="27" />
      </clipPath>
    </defs>
    <g
      id="Medical-history-icon_1"
      data-name="Medical-history-icon – 1"
      clipPath="url(#clip-Medical-history-icon_1)"
    >
      <g id="calendar" transform="translate(0 -20.9)">
        <g id="Group_11" data-name="Group 11" transform="translate(0 20.9)">
          <g id="Group_10" data-name="Group 10">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M27.158,23.2h-1.9V21.689a.789.789,0,1,0-1.578,0V23.2H15.943V21.689a.789.789,0,0,0-1.578,0V23.2H6.551V21.689a.789.789,0,1,0-1.578,0V23.2H3.149A3.194,3.194,0,0,0,0,26.393v18.35A3.149,3.149,0,0,0,3.149,47.9h24A3.148,3.148,0,0,0,30.3,44.751V26.393A3.182,3.182,0,0,0,27.158,23.2Zm1.571,21.546a1.575,1.575,0,0,1-1.563,1.578H3.149a1.57,1.57,0,0,1-1.571-1.571V26.393a1.606,1.606,0,0,1,1.571-1.618H4.972V25.92a.789.789,0,1,0,1.578,0V24.775h7.813V25.92a.789.789,0,0,0,1.578,0V24.775h7.735V25.92a.789.789,0,1,0,1.578,0V24.775h1.9a1.605,1.605,0,0,1,1.571,1.618Z"
              transform="translate(0 -20.9)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_13"
          data-name="Group 13"
          transform="translate(10.807 29.858)"
        >
          <g id="Group_12" data-name="Group 12">
            <path
              id="Path_2"
              data-name="Path 2"
              d="M140.554,135h-1.5a.856.856,0,0,0,0,1.712h1.5a.856.856,0,0,0,0-1.712Z"
              transform="translate(-138.2 -135)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_15"
          data-name="Group 15"
          transform="translate(16.25 29.858)"
        >
          <g id="Group_14" data-name="Group 14">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M210.154,135h-1.5a.856.856,0,0,0,0,1.712h1.5a.856.856,0,0,0,0-1.712Z"
              transform="translate(-207.8 -135)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_17"
          data-name="Group 17"
          transform="translate(21.74 29.858)"
        >
          <g id="Group_16" data-name="Group 16">
            <path
              id="Path_4"
              data-name="Path 4"
              d="M280.354,135h-1.5a.856.856,0,1,0,0,1.712h1.5a.856.856,0,1,0,0-1.712Z"
              transform="translate(-278 -135)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_19"
          data-name="Group 19"
          transform="translate(10.807 35.275)"
        >
          <g id="Group_18" data-name="Group 18">
            <path
              id="Path_5"
              data-name="Path 5"
              d="M140.554,204h-1.5a.856.856,0,0,0,0,1.712h1.5a.856.856,0,0,0,0-1.712Z"
              transform="translate(-138.2 -204)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_21"
          data-name="Group 21"
          transform="translate(5.318 35.275)"
        >
          <g id="Group_20" data-name="Group 20">
            <path
              id="Path_6"
              data-name="Path 6"
              d="M70.363,204H68.856a.856.856,0,1,0,0,1.712h1.5A.856.856,0,0,0,70.363,204Z"
              transform="translate(-68 -204)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_23"
          data-name="Group 23"
          transform="translate(16.25 35.275)"
        >
          <g id="Group_22" data-name="Group 22">
            <path
              id="Path_7"
              data-name="Path 7"
              d="M210.154,204h-1.5a.856.856,0,0,0,0,1.712h1.5a.856.856,0,0,0,0-1.712Z"
              transform="translate(-207.8 -204)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_25"
          data-name="Group 25"
          transform="translate(21.74 35.275)"
        >
          <g id="Group_24" data-name="Group 24">
            <path
              id="Path_8"
              data-name="Path 8"
              d="M280.354,204h-1.5a.856.856,0,1,0,0,1.712h1.5a.856.856,0,1,0,0-1.712Z"
              transform="translate(-278 -204)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_27"
          data-name="Group 27"
          transform="translate(10.807 40.613)"
        >
          <g id="Group_26" data-name="Group 26">
            <path
              id="Path_9"
              data-name="Path 9"
              d="M140.554,272h-1.5a.856.856,0,1,0,0,1.712h1.5a.856.856,0,0,0,0-1.712Z"
              transform="translate(-138.2 -272)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_29"
          data-name="Group 29"
          transform="translate(5.318 40.613)"
        >
          <g id="Group_28" data-name="Group 28">
            <path
              id="Path_10"
              data-name="Path 10"
              d="M70.363,272H68.856a.856.856,0,1,0,0,1.712h1.5A.856.856,0,0,0,70.363,272Z"
              transform="translate(-68 -272)"
              fill="#1999cf"
            />
          </g>
        </g>
        <g
          id="Group_31"
          data-name="Group 31"
          transform="translate(16.25 40.613)"
        >
          <g id="Group_30" data-name="Group 30">
            <path
              id="Path_11"
              data-name="Path 11"
              d="M210.154,272h-1.5a.856.856,0,0,0,0,1.712h1.5a.856.856,0,1,0,0-1.712Z"
              transform="translate(-207.8 -272)"
              fill="#1999cf"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MedicalHistoryOne;
