import React from 'react';
import { node } from 'prop-types';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import DesktopHeader from './Headers/DesktopHeader';
import LayoutBase from './LayoutBase';

const propTypes = {
  children: node.isRequired,
};

const Content = styled(Container)(({ theme }) =>
  css({
    flex: '1 1 0',
    width: '100%',
    mt: 16,
    p: 6,
    [theme.mq.up('lg')]: {
      mt: 0,
      p: 12,
      pb: 6,
    },
  }),
);

const LoggedInLayout = ({ children }) => (
  <LayoutBase desktopHeader={DesktopHeader}>
    <Content as="section">{children}</Content>
  </LayoutBase>
);

LoggedInLayout.propTypes = propTypes;

export default LoggedInLayout;
