export const LANGUAGES = {
  ES: 'es',
  EN: 'en',
  ZH: 'zh-Hans',
  HY: 'hy',
  KO: 'ko',
};

export const LANGUAGES_LABELS = {
  [LANGUAGES.EN]: 'English',
  [LANGUAGES.ES]: 'Español (Spanish)',
  [LANGUAGES.ZH]: '中文 (Mandarin Chinese)',
  [LANGUAGES.HY]: 'Հայերեն (Armenian)',
  [LANGUAGES.KO]: '한국어 (Korean)',
};

export const LANGUAGES_LIST = Object.entries(LANGUAGES_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);
