import client from './client';

const usersClient = {
  async me() {
    const { medicalHistory, patientForms, ...user } = await client.get(
      '/users/me/',
    );
    const formatMedicalHistory = medicalHistory.reduce(
      (acc, curr) => ({ ...acc, [curr.formType]: curr }),
      {},
    );
    const formatPatientForms = patientForms.reduce(
      (acc, curr) => ({ ...acc, [curr.formType]: curr }),
      {},
    );

    return {
      user: {
        ...user,
        patientForms: formatPatientForms,
        medicalHistory: formatMedicalHistory,
      },
    };
  },
  profile(values) {
    return client.put('/users/profile/', values);
  },
  verifyAddress(values) {
    return client.post('/users/verifyaddress/', values);
  },
};

export default usersClient;
