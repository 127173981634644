import React from 'react';
import { node, string, func, oneOfType } from 'prop-types';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import BaseDropdown, {
  useDropdownState,
} from '@bit/medicalwebexperts.mwe-ui.dropdown';

const propTypes = {
  toggle: node.isRequired,
  children: oneOfType([func, node]).isRequired,
  ariaLabel: string.isRequired,
};

const Dropdown = ({ children, toggle, ariaLabel, ...props }) => {
  const dropdown = useDropdownState();

  return (
    <BaseDropdown state={dropdown}>
      <BaseDropdown.Toggle
        caret={<Icon name="MdKeyboardArrowDown" size={4} />}
        {...props}
      >
        {toggle}
      </BaseDropdown.Toggle>
      <BaseDropdown.Menu bg="white" minWidth={100} aria-label={ariaLabel}>
        {typeof children === 'function' ? children(dropdown) : children}
      </BaseDropdown.Menu>
    </BaseDropdown>
  );
};

Dropdown.propTypes = propTypes;
Dropdown.Item = BaseDropdown.Item;

export default Dropdown;
