import React from 'react';

const Address = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="21"
    height="27"
    viewBox="0 0 21 27"
  >
    <defs>
      <clipPath id="clip-Address-ver-icon">
        <rect width="21" height="27" />
      </clipPath>
    </defs>
    <g id="Address-ver-icon" clipPath="url(#clip-Address-ver-icon)">
      <g id="pin" transform="translate(-48.08)">
        <g id="Group_176" data-name="Group 176" transform="translate(48.08)">
          <g id="Group_175" data-name="Group 175" transform="translate(0)">
            <path
              id="Path_33"
              data-name="Path 33"
              d="M58.054,0A9.985,9.985,0,0,0,48.08,9.974a11.508,11.508,0,0,0,.846,3.84,16.085,16.085,0,0,0,1.153,2.156l6.839,10.362a1.3,1.3,0,0,0,2.273,0L66.03,15.97a16.018,16.018,0,0,0,1.153-2.156,11.5,11.5,0,0,0,.845-3.84A9.985,9.985,0,0,0,58.054,0ZM66.1,13.359a14.737,14.737,0,0,1-1.051,1.965L58.211,25.686c-.135.2-.178.2-.313,0L51.059,15.324a14.744,14.744,0,0,1-1.051-1.966,10.445,10.445,0,0,1-.755-3.384,8.8,8.8,0,0,1,17.6,0A10.454,10.454,0,0,1,66.1,13.359Z"
              transform="translate(-48.08)"
              fill="#1999cf"
            />
            <path
              id="Path_34"
              data-name="Path 34"
              d="M117.36,64.008a5.28,5.28,0,1,0,5.28,5.28A5.286,5.286,0,0,0,117.36,64.008Zm0,9.387a4.107,4.107,0,1,1,4.107-4.107A4.112,4.112,0,0,1,117.36,73.4Z"
              transform="translate(-107.386 -59.314)"
              fill="#1999cf"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Address;
