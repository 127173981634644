import i18n from 'i18next';
import moment from 'moment';
import { setLocale } from 'yup';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import langEn from './assets/locales/en.json';
import langEs from './assets/locales/es.json';
import langZh from './assets/locales/zh.json';
import langHy from './assets/locales/hy.json';
import langKo from './assets/locales/ko.json';
import 'moment/locale/es';
import { LANGUAGES } from './constants/languages';
import getCurrentLanguage from './utils/getCurrentLanguage';

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: {
        zh: ['zh-Hans'],
        default: ['en'],
      },
      load: 'languageOnly',
      debug: process.env.NODE_ENV === 'development',
      resources: {
        [LANGUAGES.EN]: { translation: langEn },
        [LANGUAGES.ES]: { translation: langEs },
        [LANGUAGES.ZH]: { translation: langZh },
        [LANGUAGES.HY]: { translation: langHy },
        [LANGUAGES.KO]: { translation: langKo },
      },
      interpolation: { escapeValue: false, skipOnVariables: false },
    },
    () => {
      setLocale({
        mixed: {
          required: 'common.validations.mixed.required',
        },
        string: {
          email: 'common.validations.string.email',
          length: ({ path, length }) => ({
            key: 'common.validations.string.length',
            values: { path, length },
          }),
          min: ({ min }) => ({
            key: 'common.validations.string.min',
            values: { min },
          }),
          max: ({ max }) => ({
            key: 'common.validations.string.max',
            values: { max },
          }),
        },
        array: {
          length: ({ path, length }) => ({
            key: 'common.validations.array.length',
            values: { path, length },
          }),
        },
        number: {
          min: ({ min }) => ({
            key: 'common.validations.number.min',
            values: { min },
          }),
          max: ({ max }) => ({
            key: 'common.validations.number.max',
            values: { max },
          }),
        },
      });
      moment.updateLocale('es', {
        months: (momentToFormat, format) => {
          if (/^MMMM/.test(format)) {
            return months[momentToFormat.month()];
          }
          return `de ${months[momentToFormat.month()]}`;
        },
        weekdays: [
          'Domingo',
          'Lunes',
          'Martes',
          'Miércoles',
          'Jueves',
          'Viernes',
          'Sábado',
        ],
      });
      moment.locale(getCurrentLanguage(i18n));
    },
  );

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
