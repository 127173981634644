import React, { lazy } from 'react';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScrollToTop from '@bit/medicalwebexperts.mwe-ui.scroll-to-top';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LoggedOutLayout from '../Layout/LoggedOutLayout';
import LoggedInLayout from '../Layout/LoggedInLayout';
import PrivateRoute from '../Routes/PrivateRoute';
import PublicRoute from '../Routes/PublicRoute';
import Loader from './Loader';

const NotFoundPage = lazy(() => import('../../pages/404/404'));
const LoginPage = lazy(() => import('../../pages/Login/LoginPage'));
const SupportPage = lazy(() => import('../../pages/Support/SupportPage'));
const SchedulerPage = lazy(() => import('../../pages/Scheduler/SchedulerPage'));
const ShippingCanceledPage = lazy(() =>
  import('../../pages/Shipping/CanceledPage'),
);

const App = () => (
  <Router>
    <Helmet titleTemplate="%s - Advanced Sleep Medicine Services" />
    <ScrollToTop />
    <ErrorBoundary>
      <Switch>
        <PublicRoute path={['/login', '/support', '/shipping-canceled']}>
          <LoggedOutLayout>
            <Loader>
              <Switch>
                <Route exact path="/login/:userId?/:code?">
                  <LoginPage />
                </Route>
                <Route exact path="/support">
                  <SupportPage />
                </Route>
                <Route exact path="/shipping-canceled">
                  <ShippingCanceledPage />
                </Route>
                <Redirect to="/login" />
              </Switch>
            </Loader>
          </LoggedOutLayout>
        </PublicRoute>

        <PrivateRoute path="/">
          <LoggedInLayout>
            <Loader>
              <Switch>
                <PrivateRoute exact path="/">
                  <SchedulerPage />
                </PrivateRoute>
                <PrivateRoute path="*">
                  <NotFoundPage />
                </PrivateRoute>
              </Switch>
            </Loader>
          </LoggedInLayout>
        </PrivateRoute>
      </Switch>
    </ErrorBoundary>
  </Router>
);

export default App;
