import React from 'react';

const Welcome = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="28"
    height="27"
    viewBox="0 0 28 27"
  >
    <defs>
      <clipPath id="clip-Welcome-icon">
        <rect width="28" height="27" />
      </clipPath>
    </defs>
    <g id="Welcome-icon" clipPath="url(#clip-Welcome-icon)">
      <path
        id="home_1_"
        data-name="home (1)"
        d="M26.772,11.744l0,0L15.756.728a2.485,2.485,0,0,0-3.515,0L1.232,11.736l-.011.011a2.485,2.485,0,0,0,1.654,4.235c.026,0,.051,0,.077,0h.439v8.105A2.912,2.912,0,0,0,6.3,27h4.309a.791.791,0,0,0,.791-.791V19.854a1.329,1.329,0,0,1,1.327-1.327h2.542A1.329,1.329,0,0,1,16.6,19.854v6.354a.791.791,0,0,0,.791.791H21.7a2.912,2.912,0,0,0,2.909-2.909V15.986h.407a2.486,2.486,0,0,0,1.759-4.242Zm-1.12,2.4a.9.9,0,0,1-.639.265h-1.2a.791.791,0,0,0-.791.791v8.9A1.329,1.329,0,0,1,21.7,25.418H18.178V19.854a2.913,2.913,0,0,0-2.909-2.909H12.727a2.913,2.913,0,0,0-2.909,2.909v5.563H6.3a1.329,1.329,0,0,1-1.327-1.327v-8.9a.791.791,0,0,0-.791-.791H3l-.037,0a.9.9,0,0,1-.622-1.541h0L13.359,1.847a.9.9,0,0,1,1.278,0L25.648,12.858l.005,0A.906.906,0,0,1,25.652,14.139Zm0,0"
        transform="translate(0.5 0)"
        fill="#1999cf"
      />
    </g>
  </svg>
);

export default Welcome;
